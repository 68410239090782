.filter-chip {
  border-color: var(--color-surface-outline);
  color: var(--mo-color-100);
}

.filter-chip:hover {
  background-color: var(--mo-color-05);
  border-color: var(--color-surface-fg-variant);
}

.filter-chip--active {
  border-color: var(--color-surface-fg-variant);
}

.filter-chip--disabled,
.filter-chip--disabled:hover {
  border-color: var(--mo-color-50);
  color: var(--mo-color-50);
}

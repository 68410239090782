.carousel {
  text-rendering: auto;

  &--primary {
    --swiper-theme-color: var(--color-primary-bg);
  }

  &--secondary {
    --swiper-theme-color: var(--color-secondary-bg);
  }

  &--destructive {
    --swiper-theme-color: var(--color-destructive-bg);
  }

  &--tertiary {
    --swiper-theme-color: var(--color-tertiary-bg);
  }

  &--white {
    --swiper-theme-color: var(--color-tertiary-fg);
    --swiper-pagination-bullet-inactive-opacity: 0.7;
  }

  &__pagination-bullet--white {
    border: 1px solid var(--color-tertiary-fg);
  }

  &__wrapper--small {
    min-height: 400px;
  }

  &__wrapper--medium {
    min-height: 500px;
  }

  &__wrapper--large {
    min-height: 600px;
  }

  &__pagination {
    z-index: 5;
  }

  &__content {
    position: relative;
    display: grid;
    top: 0;
    z-index: 99;
    height: 100%;
    padding: var(--padding-m) var(--padding-2xl);

    &--top {
      align-items: start;
    }

    &--middle {
      align-items: center;
    }

    &--bottom {
      align-items: end;
    }
  }

  &__background-image {
    &--cover {
      object-fit: cover;
    }

    &--contain {
      object-fit: contain;
    }
  }

  &__background {
    height: auto;

    &--primary {
      background-color: var(--color-primary-bg);
    }

    &--secondary {
      background-color: var(--color-secondary-bg);
    }

    &--tertiary {
      background-color: var(--color-tertiary-bg);
    }
  }

  @media (width <= 48em) {
    &__content {
      padding: var(--padding-s);
    }
  }
}

@media (width <= 48em) {
  .carousel {
    --swiper-navigation-size: 22px;
    --swiper-navigation-top-offset: 95%;
  }
}
